import React, {useState, useCallback, useEffect} from 'react'
import ProjectSearchComponent from '../../Utilities/ProjectSearchComponent'
import { useDropzone } from 'react-dropzone';
import MainModal from '../../Modals/MainModal';
import CreateItem from '../../Items/CreateItem/CreateItem';
import ToolBulkSearchComponent from '../../Utilities/ToolBulkSearchComponent';
import { useSelector } from 'react-redux';
import api from '../../../store/api';
import FeedbackModal from '../../Utilities/FeedbackModal';
import CreateTool from '../../Tools/CreateTool';
import { useParams } from 'react-router';
import ItemSerialBulkSearchComponent from '../../Utilities/ItemSerialBulkSearchComponent';

export default function EditAsset({setData, data = null}) {
    
    const [assetData, setAssetData] = useState({
        assetName: '',
        assetProject: 0,
        assetWarehouse: 0,
        assetWarehouseZone: 0,
        assetDescription: '',
        newImages: [],
        removedImages: [],
        newItems: [],
        removedItems: [],
        newTools: [],
        removedTools: [],
        assetHeight: 0.0,
        assetLength: 0.0,
        assetWidth: 0.0,
        assetCustody: 0
    })

    const { id } = useParams()
    const [agencies, setAgencies] = useState([])
    const getAgencies = () => {
            api.get('/api/agencies/agencies', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                const data = response.data.results
                setAgencies(data)
            })
            .catch((error) => {
            setRes({
                message: error.response.data.error,
                severity: "error",
                show: true
            })
        })
    }

    useEffect(() => {
        if (data) {
            setAssetData({
                assetName: data.asset_name,
                assetProject: data.asset_project ? data.asset_project.project_id : null,
                assetWarehouse: data.asset_warehouse ? data.asset_warehouse.warehouse_id : null,
                assetWarehouseZone: data.warehouse_zone,
                assetDescription: data.asset_description,
                assetImages: data.images,
                assetItems: data.asset_items,
                assetTools: data.asset_tools,
                assetHeight: data.asset_height,
                assetLength: data.asset_length,
                assetWidth: data.asset_width,
                assetCustody: data.asset_custody === null ? 0 : data.asset_custody.id,
                newImages: [],
                removedImages: [],
                newItems: [],
                removedItems: [],
                newTools: [],
                removedTools: [],
            })

            setAssetImages(data.images)
            getAgencies()
            setSelectedItems(data.det_items)
            setProjectSearchQuery(data.asset_project.project_name)
            setSelectedTools(data.det_tools)
            setEdit(true)
        }
    }, [data])

    const [errors, setErrors] = useState({
        assetNameErrors: '',
        assetProjectErrors: '',
        assetWarehouseErrors: '',
        assetWarehouseZoneErrors: '',
        assetDescriptionErrors: '',
        assetImagesErrors: '',
        assetItemsErrors: '',
        assetToolsErrors: ''
    })

    const [successfullSubmission, setsuccessfullSubmission] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [currentTab, setCurrentTab] = useState(1)
    const maxTab = 3
    const [selectedTools, setSelectedTools] = useState([])
    const [toolModal, setToolModal] = useState(false)
    const handleChange = (evt) => {
        const target = evt.target
        const name = target.name
        const value = target.value
        setAssetData({...assetData, [name]: value})
    }

    const token = useSelector((state) => state.loginSlice.token);
    const [assetImages, setAssetImages] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
		const newImages = acceptedFiles
			.filter(file => !assetImages.some(img => img.name === file.name))
			.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			setAssetImages(prevImages => {
				const updatedImages = [...prevImages, ...newImages];
				return updatedImages;
			});
	
			setAssetData(prevData => {
				const updatedAssetData = {
					...prevData,
					newImages: [...prevData.newImages, ...newImages]
				};
				return updatedAssetData;
			});
	}, [assetImages, setAssetImages, setAssetData]);
	
	const [submitLoading, setSubmitLoading] = useState(false)
    const [itemModal, setItemModal] = useState(false)
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop
	});

	const handleRemoveImage = (index, obj = null) => {
		setAssetImages((prevImages) => prevImages.filter((_, i) => i !== index));
        const _removedImages = assetData.removedImages
        const _newImages = assetData.newImages
        if (assetImages[index].id !== undefined) {
            _removedImages.push(assetImages[index].id)
            setAssetData({...assetData, removedImages: _removedImages})
        } else {
            const i = assetData.newImages.indexOf(obj)
            _newImages.splice(i, 1)
            setAssetData({...assetData, newImages: _newImages})
        }
	};

    const handleRemoveItem = (indexToRemove, itemId) => {
		setSelectedItems(prevItems => prevItems.filter((_, index) => index !== indexToRemove));
		const _itemIds = data.asset_items
        let i = _itemIds.indexOf(itemId)
        if (i !== -1) {
            const removedItem = _itemIds.splice(i, 1)
            const _removedItems = assetData.removedItems
            _removedItems.push(removedItem[0]) 
            setAssetData({...assetData, removedItems: _removedItems})
        }

        const _newItems = assetData.newItems
        i = _newItems.indexOf(itemId)
        if (i !== -1) {
            _newItems.splice(index, 1)
            setAssetData({...assetData, newItems: _newItems})
        }
	};

    const [res, setRes] = useState({
		message: '',
		severity: "success",
		show: false
	})

    const handleRemoveTool = (indexToRemove, toolId) => {
		setSelectedTools(prevTools => prevTools.filter((_, index) => index !== indexToRemove));
		const _toolIds = assetData.assetTools
		let i = _toolIds.indexOf(toolId)
        if (i !== -1) {
            const removedTool = _toolIds.splice(i, 1)
            const _removedTools = assetData.removedTools
            _removedTools.push(removedTool[0]) 
            setAssetData({...assetData, removedTools: _removedTools})
        }

        const _newTools = assetData.newTools
        i = _newTools.indexOf(toolId)
        if (i !== -1) {
            _newTools.splice(i, 1)
            setAssetData({...assetData, newTools: _newTools})
        }
	};

    const handleSubmit = (evt) => {
        evt.preventDefault()
        const formData = new FormData();
        
        formData.append('assetName', assetData.assetName);
        formData.append('assetDescription', assetData.assetDescription);
        formData.append('assetProject', assetData.assetProject);
        formData.append('assetWidth', assetData.assetWidth);
        formData.append('assetLength', assetData.assetLength);
        formData.append('assetHeight', assetData.assetHeight);
        formData.append('removedImages', assetData.removedImages);
        formData.append('newTools', assetData.newTools);
        formData.append('removedTools', assetData.removedTools);
        formData.append('newItems', assetData.newItems);
        formData.append('removedItems', assetData.removedItems);
        formData.append('assetCustody', assetData.assetCustody);

        assetData.newImages.forEach((img, index) => {
            formData.append('newImages', img);
        });

		if (formData) {
			setSubmitLoading(true)
			api.put(`/api/assets/update/${id}/`, formData,
                {headers: {
						'Content-Type': 'multipart/form-data',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 200) {
                        setRes({
							message: response.data.message,
							severity: "success",
							show: true
						})

						setErrors({
							aassetNameError: '',
							aassetLengthError: '',
							aassetWidthError: '',
							aassetHeightError: '',
							aassetDescriptionError: '',
                            assetImagesError: '',
                            assetItemsError: '',
                            assetToolsError: ''
						})

						setSelectedItems([])
						setCurrentTab(1)
						setAssetImages([])
						setSubmitLoading(false)
                        setSelectedTools([])
                        setData(response.data.data)
                    }
                })
                .catch((error) => {
                    setRes({
						message: error.response.data.error,
						severity: "error",
						show: true
					})
					setSubmitLoading(false)
                })
		}
    }

    const [projectSearchQuery, setProjectSearchQuery] = useState('')
    const [edit, setEdit] = useState(false)

  return (
    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid" id="kt_create_account_stepper" data-kt-stepper="true">
    <MainModal 
        handleClose={() => setItemModal(false)} 
        show={itemModal}
        modalTitle='Create a new item'>
            <CreateItem madeModal={true} returnNewItem={(item) => {
                setSelectedItems([...selectedItems, item])
                const _itemsId = assetData.newItems
                _itemsId.push(item.id)
                setAssetData({...assetData, assetItems: _itemsId})
                setItemModal(false)
            }} />
    </MainModal>
    <MainModal 
        handleClose={() => setToolModal(false)} 
        show={toolModal}
        modalTitle='Create a new tool'>
        <CreateTool returnResults={(tool, message) => {
                if (tool) {
                    if (!assetData.assetTools.includes(tool.id)) {
                        const _tools = assetData.newTools
                        _tools.push(tool.id)
                        tool['name'] = tool.tool_name
                        setAssetData({...assetData, assetTools: _tools})
                        setSelectedTools([...selectedTools, tool])
                    }
                }
                setToolModal(false)
                setRes({
                    message: message,
                    severity: "success",
                    show: true
                })
            }
        } />
    </MainModal>
    <FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
    }}/>
    <div className="d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-xl-300px w-xxl-400px me-9">
    {/*begin::Wrapper*/}
    <div className="px-6 px-lg-10 px-xxl-15 py-20">
        {/*begin::Nav*/}
        <div className="stepper-nav">
            {/*begin::Step 1*/}
            <div className={currentTab > 1 ? "stepper-item completed" : "stepper-item current"} data-kt-stepper-element="nav">
                {/*begin::Line*/}
                <div className="stepper-line w-40px"></div>
                {/*end::Line*/}
                {/*begin::Icon*/}
                <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">1</span>
                </div>
                {/*end::Icon*/}
                {/*begin::Label*/}
                <div className="stepper-label">
                    <h3 className="stepper-title">Basic Asset Details</h3>
                    <div className="stepper-desc ftw-bold">Add basic details for your asset.</div>
                </div>
                {/*end::Label*/}
            </div>
            {/*end::Step 1*/}
            {/*begin::Step 2*/}
            <div className={currentTab > 2 ? "stepper-item completed" : currentTab === 2 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element="nav">
                {/*begin::Line*/}
                <div className="stepper-line w-40px"></div>
                {/*end::Line*/}
                {/*begin::Icon*/}
                <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">2</span>
                </div>
                {/*end::Icon*/}
                {/*begin::Label*/}
                <div className="stepper-label">
                    <h3 className="stepper-title">Upload Asset Images</h3>
                    <div className="stepper-desc ftw-bold">Upload Images that wholesome asset Images.</div>
                </div>
                {/*end::Label*/}
            </div>
            {/*end::Step 2*/}
            {/*begin::Step 3*/}
            <div className={currentTab > 3 ? "stepper-item completed" : currentTab === 3 ? "stepper-item current" : "stepper-item"} data-kt-stepper-element="nav">
                {/*begin::Line*/}
                <div className="stepper-line w-40px"></div>
                {/*end::Line*/}
                {/*begin::Icon*/}
                <div className="stepper-icon w-40px h-40px">
                    <i className="stepper-check fas fa-check"></i>
                    <span className="stepper-number">3</span>
                </div>
                {/*end::Icon*/}
                {/*begin::Label*/}
                <div className="stepper-label">
                    <h3 className="stepper-title">Configure Items</h3>
                    <div className="stepper-desc ftw-bold">Configure items that belong to your asset.</div>
                </div>
                {/*end::Label*/}
            </div>
        </div>
        {/*end::Nav*/}
    </div>
    {/*end::Wrapper*/}
    </div>
    {/*begin::Aside*/}
    {/*begin::Content*/}
    <div className="d-flex flex-row-fluid flex-center bg-body rounded">
    {/*begin::Form*/}
    <form className="py-20 w-100 w-xl-700px px-9 fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate" id="kt_create_account_form">
        {/*begin::Step 1*/}
        <div className={currentTab === 1 && "current"} data-kt-stepper-element="content">
            {/*begin::Wrapper*/}
            <div className="w-100">
                {/*begin::Heading*/}
                <div className="pb-10 pb-lg-15">
                    {/*begin::Title*/}
                    <h3 className="ftw-bolder d-flex align-items-center text-dark">Basic Asset Details
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h3>
                    {/*end::Title*/}
                    {/*begin::Notice*/}
                    <div className="text-muted ftw-bold fs-6">Add basic details for your asset</div>
                    {/*end::Notice*/}
                </div>
                {/*end::Heading*/}
                {/*begin::Input group*/}
                <div className="row"> 
                <div className="d-flex flex-column mt-7 fv-row fv-plugins-icon-container col-md-6">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span className="required">Asset Name</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide a unique name for the asset"></i>
                    </label>
                    <input autoComplete="off" type="text" className="form-control form-control-solid" placeholder="" name="assetName" value={assetData.assetName} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-7 fv-row fv-plugins-icon-container col-md-6">
                    <ProjectSearchComponent returnResults = {(id) => {
                        setAssetData({...assetData, assetProject: id})
                    }} successfullSubmission={successfullSubmission} edit={edit} projectName={projectSearchQuery}/> 
                </div>
                <div className="d-flex flex-column mt-3 fv-row fv-plugins-icon-container col-md-6">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span className="required">Asset Length</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide height for this asset"></i>
                    </label>
                    <input autoComplete="off" type="number" min={0} className="form-control form-control-solid" placeholder="" name="assetLength" value={assetData.assetLength} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-3 fv-row fv-plugins-icon-container col-md-6">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span className="required">Asset Width</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide width for this asset"></i>
                    </label>
                    <input autoComplete="off" type="number" min={0} className="form-control form-control-solid" placeholder="" name="assetWidth" value={assetData.assetWidth} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="d-flex flex-column mt-3 fv-row fv-plugins-icon-container col-md-6">
                    <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                        <span className="required">Asset Height</span>
                        <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="Provide height for this asset"></i>
                    </label>
                    <input autoComplete="off" type="number" min={0} className="form-control form-control-solid" placeholder="" name="assetHeight" value={assetData.assetHeight} onChange={handleChange} />
                <div className="fv-plugins-message-container invalid-feedback"></div></div>
                <div className="col-md-6 fv-row fv-plugins-icon-container mt-3">
                    <label className="required fs-6 fwt-bold mb-2">Asset Custody</label>
                    <select id="dropdown" name="assetCustody" className='form-control form-control-solid' onChange={handleChange} value={assetData.assetCustody}>
                        <option value="0">-- Select Asset Custodian --</option>
                        {agencies.map((agency) => {return(<option value={agency.id}>{agency.name}</option>)})}
                    </select>
                    <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.brandAgencyError}</div></div>
                </div>
                <div className="mb-7 fv-row fv-plugins-icon-container col-md-12">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">Asset Description</label>
                    <textarea name="assetDescription" rows="10" cols="30" className='form-control form-control-solid' value={assetData.assetDescription} onChange={handleChange}></textarea>
                </div>
                </div>
                {/*end::Input group*/}
            </div>
            {/*end::Wrapper*/}
        </div>
        {/*end::Step 1*/}
        {/*begin::Step 2*/}
        <div className={currentTab === 2 && "current"} data-kt-stepper-element="content">
            {/*begin::Wrapper*/}
            <div className="w-100">
                {/*begin::Heading*/}
                <div className="pb-10 pb-lg-15">
                    {/*begin::Title*/}
                    <h3 className="ftw-bolder d-flex align-items-center text-dark">Upload Asset Images
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h3>
                    {/*end::Title*/}
                    {/*begin::Notice*/}
                    <div className="text-muted ftw-bold fs-6">Upload Images that wholesome asset Images</div>
                    {/*end::Notice*/}
                </div>
                {/*end::Heading*/}
                <div className="fv-row">
                {/*begin::Dropzone*/}
                <div {...getRootProps({ className: 'dropzone' })} >
                    {/*begin::Message*/}
                    <div className="dz-message needsclick">
                        {/*begin::Icon*/}
                        <i className="bi bi-file-earmark-arrow-up text-primary-beysix fs-3x"></i>
                        {/*end::Icon*/}
                        <input autoComplete="off" {...getInputProps()} />
                        {/*begin::Info*/}
                        <div className="ms-4">
                            <h3 className="fs-5 ftw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
                            <span className="fs-7 ftw-bold text-gray-400">Upload up to 10 images</span>
                        </div>
                        {/*end::Info*/}
                    </div>
                </div>
                {/*end::Dropzone*/}
            </div>
            {assetImages.length > 0 && <div className="ms-4" style={{margin: "20px 10px"}}><h3 className="fs-5 ftw-bold text-gray-900 mb-1">{`You have selected ${assetImages.length} images`}</h3></div>}
            <div className='upload-images-wrapper row'>
                {assetImages.map((file, index) => (
                file.preview ? <div key={index} className='single-image-wrapper col-md-3'>
                    <div className='uploaded-image-overlay'></div>
                    <div className='delete-uploaded-image' onClick={() => handleRemoveImage(index, file)}>
                    <span className='bi bi-x fs-3'></span>
                    </div>
                    <img className = 'uploaded-image' src={file.preview} alt={file.name} />
                </div> : 
                <div key={index} className='single-image-wrapper col-md-3'>
                    <div className='uploaded-image-overlay'></div>
                    <div className='delete-uploaded-image' onClick={() => handleRemoveImage(index, file)}>
                    <span className='bi bi-x fs-3'></span>
                    </div>
                <img className = 'uploaded-image' src={file.image_url} alt={file.name} />
            </div>
                ))}
            </div>
            </div>
            {/*end::Wrapper*/}
        </div>
        {/*end::Step 2*/}
        {/*begin::Step 3*/}
        <div className={currentTab === 3 && "current"} data-kt-stepper-element="content">
            {/*begin::Wrapper*/}
            <div className="w-100">
                {/*begin::Heading*/}
                <div className="pb-10 pb-lg-15">
                    {/*begin::Title*/}
                    <h3 className="ftw-bolder d-flex align-items-center text-dark">Configure Items
                    <i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h3>
                    {/*end::Title*/}
                    {/*begin::Notice*/}
                    <div className="text-muted ftw-bold fs-6">Configure items that belong to your asset</div>
                    {/*end::Notice*/}
                </div>
                {/*end::Heading*/}
                <div className='row'>
                    <div className='col-md-11'>
                        <ItemSerialBulkSearchComponent returnResults = {(item) => {
                        if (item) {
                            if (!assetData.newItems.includes(item.id)) {
                                const _items = assetData.newItems
                                _items.push(item.id)
                                setAssetData({...assetData, newItems: _items})
                                setSelectedItems([...selectedItems, item])
                            }
                        }
                        }} successfullSubmission={successfullSubmission} />
                </div>
                <div className='col-md-1'>
                    <div className='add-item-btn-wrapper'>
                        <span className='add-item-btn' onClick={() => setItemModal(true)}><i className="bi bi-plus"></i></span>
                    </div>
                </div>
                </div>

                <div style={{maxHeight: '350px', overflow: 'auto'}}>
                    {selectedItems.map((item, index) => <div className="alert alert-beysix d-flex align-items-center mb-2">
                        <div className="d-flex" key={index}>
                            <div className="d-flex">
                            {item.first_image ?
                                <div className='asset-item-image-wrapper'>
                                    <img className='asset-item-image' src={item.first_image && item.first_image.image_url} alt={item.item_name} />
                                </div> :
                                <div className='item-initial'>
                                    <span>{item.item_name[0]}</span>
                                </div>}
                                <div className="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                                    <h5 className="mb-1">{item.name}</h5>
                                    <span>{item.serial}</span>
                                </div>
                            </div>
                            <div className='cancel-item-btn'>
                            <span className='bi bi-x fs-3 cancel-icon' onClick={() => handleRemoveItem(index, item.id)}></span>
                            </div>
                    </div>
                </div>)}
            </div>
            {/*end::Wrapper*/}
        </div>
        </div>
        {/*begin::Actions*/}
        <div className="d-flex flex-stack pt-10">
            {/*begin::Wrapper*/}
            <div className="mr-2">
                {currentTab > 1 && <button type="button" className="btn btn-lg btn-light-primary me-3" onClick={() => setCurrentTab(currentTab - 1)}>
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr063.svg*/}
                <span className="svg-icon svg-icon-4 me-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
                        <path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
                    </svg>
                </span>
                {/*end::Svg Icon*/}Back</button>}
            </div>
            {/*end::Wrapper*/}
            {/*begin::Wrapper*/}
            <div>
                {currentTab === maxTab && <button type="button" onClick={handleSubmit} className="btn btn-lg btn-beysix me-3" disabled={submitLoading ? true : false}>
                    {!submitLoading ? <span className="indicator-label">Submit
                    {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                    <span className="svg-icon svg-icon-3 ms-2 me-0 svg-icon-white">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                            <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                        </svg>
                    </span>
                    {/*end::Svg Icon*/}</span> : 
                    <span className="indicator-progress">Please wait...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
                </button>}
                {currentTab < maxTab && <button type="button" onClick={() => setCurrentTab(currentTab + 1)} className="btn btn-lg btn-beysix" data-kt-stepper-action="next">Continue
                {/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
                <span className="svg-icon svg-icon-4 ms-1 me-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
                        <path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
                    </svg>
                </span>
                {/*end::Svg Icon*/}</button>}
            </div>
            {/*end::Wrapper*/}
        </div>
        {/*end::Actions*/}
    <div></div><div></div><div></div><div></div></form>
    {/*end::Form*/}
    </div>
    {/*end::Content*/}
    </div>
  )
}
