import React, {useState, useEffect, useRef} from 'react'
import FeedbackModal from '../../Utilities/FeedbackModal';
import api from '../../../store/api';
import {useSelector} from 'react-redux'
 
export default function CreateUser() {
    const [formData, setFormData] = useState({
		firstName: '',
		lastName: '',
		email: '',
		userType: 'staff',
		occupation: null,
		client: 0
	});

    const handleFormInputChange = (e) => {
        setCreateUserFormData({
            ...createUserFormData,
            [e.target.name]: e.target.value,
        });
    };

	const [selectedRoles, setSelectedRoles] = useState([]);

    const handleCheckboxClick = (roleId) => {
		const isSelected = selectedRoles.includes(roleId);
		if (isSelected) {
			setSelectedRoles(selectedRoles.filter(selectedRoleId => selectedRoleId !== roleId));
		} else {
			setSelectedRoles([...selectedRoles, roleId]);
		}
	};

	const [clients, setClients] = useState([])
	const getClients = () => {
        api.get('/api/clients/clients', {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
        .then((response) => {
            const data = response.data.results
            setClients(data)
        })
        .catch((error) => {
            setRes({
                message: error.response.data.error,
                severity: "error",
                show: true
            })
        })
    }

	useEffect(() => {
		getRoles()
		getClients()
	}, [])
	const maxStep = 1
	const token = useSelector((state) => state.loginSlice.token);

	const firstName = useRef(null)
	const lastName = useRef(null)
	const userEmail = useRef(null)
	const occupation = useRef(null)
	const client = useRef(null)

	const [step, setStep] = useState(0)
	const [roles, setRoles] = useState([])

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = formData
		data["selectedRoles"] = selectedRoles
		const validationErrors = validateFormData(formData);
		if (Object.keys(validationErrors).length === 0) {
			setLoading(true)
			api.post(
				'/api/users/invite-user/', data, {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Token ${token}`,
					},
				})
			.then((response) => {
				setStep(0)
				setSelectedRoles([])
				setFormData({
					firstName: '',
					lastName: '',
					email: '',
					userType: 'staff',
					occupation: null
				})
				setRes({
					message: response.data.message,
					severity: "success",
					show: true
				})
				setLoading(false);
			})
			.catch((error) => {
				const message = error.response.data.error
				const severity = "error"
				const show = true
				setRes({
					message: message,
					severity: severity,
					show: show
				})
				setLoading(false);
			})
		} else {
			setErrors(validationErrors);
			setLoading(false)
		}
	};
	const [res, setRes] = useState({
		message: "",
		severity: "success",
		show: false
	})

	const [errors, setErrors] = useState({});
	const [loading, setLoading] = useState(false)
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		setErrors({ ...errors, [name]: '' });
		if (name == 'password') {
			const {strong, feedback} = passwordStrengthValidator(value)
			!strong && setErrors({ ...errors, [name]: feedback })
    }
    e.target.classList.remove('input-is-invalid')
};
	
	const validateFormData = (data) => {
		const errors = {};
		if (!data.firstName.trim()) {
		  errors.firstName = 'First name is required';
		  firstName.current.classList.add('input-is-invalid')
		}

		if (!data.lastName.trim()) {
		  errors.lastName = 'Last name is required';
		  lastName.current.classList.add('input-is-invalid')
		}

		if (!/\S+@\S+\.\S+/.test(data.email)) {
		  errors.email = 'Invalid email address';
		  userEmail.current.classList.add('input-is-invalid')
		}

		if (selectedRoles.length === 0 && step === 1) {
			errors.selectedRoles = "User must have atleast one role selected."
			setRes({
				message: "User must have atleast one role selected.",
				severity: "error",
				show: true
			})
		}

		if (0 === parseInt(data.client) && step === 0 && data.userType === 'client') {
			errors.client = 'Client user must be assigned to a client/organization.';
		  	client.current.classList.add('input-is-invalid')
		}

		return errors;
	};

	const getRoles = async () => {
        const response = await api.get('/api/users/groups/', 
            {
                headers: {
					'Content-Type': 'application/json',
					 Authorization: `Token ${token}`,
				},
            }
        );
        setRoles(response.data.data);
    }

	const handleContinue = () => {
		const validationErrors = validateFormData(formData);
		if (Object.keys(validationErrors).length === 0) {
			setStep(step + 1)
		} else {
			setErrors(validationErrors);
		}
	}

    return (
		<div className='card'>
		<FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
		<div className="stepper stepper-links d-flex flex-column between" id="kt_create_account_stepper" data-kt-stepper="true">
		<div className="stepper-nav py-3">
			<div className={step === 0 ? "stepper-item current": "stepper-item completed"} data-kt-stepper-element="nav">
				<h3 className="stepper-title">User Information</h3>
			</div>
			<div className={step === 1 ? "stepper-item current": "stepper-item"} data-kt-stepper-element="nav">
				<h3 className="stepper-title">Assign Roles and Permissions</h3>
			</div>
		</div>
		<form className="mx-auto mw-750px w-100 py-10 fv-plugins-bootstrap5 fv-plugins-framework">
			<div className={step === 0 ? "current": step > 0 ? "completed" : null} data-kt-stepper-element="content">
				<div className="w-100">
					<div className="pb-10 pb-lg-15">
						{/*begin::Title*/}
						<h2 className="fw-bolder d-flex align-items-center text-dark">User Information
						<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="basic user information" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
						{/*end::Title*/}
						{/*begin::Notice*/}
						<div className="text-muted fw-bold fs-6">Enter the basic information of the user you wish to invite.</div>
						{/*end::Notice*/}
					</div>
			{/* <form className="form w-lg-800px" noValidate id="kt_sign_up_form" style={{margin: '30px auto'}} onSubmit={handleSubmit} > */}
			<div className="w-100">
					{/*begin::Heading*/}
					<div className="pb-2 pb-lg-2">
						<div className="text-black fwt-bold fs-6">User Type</div>
						{/*end::Notice*/}
					</div>
					<div className="fv-row">
						{/*begin::Row*/}
						<div className="row">
							{/*begin::Col*/}
							<div className="col-lg-4">
								{/*begin::Option*/}
								<input type="radio" className="btn-check" name="userType" value="staff" id="kt_create_account_form_account_type_personal" checked={formData.userType === 'staff'} onChange={handleChange}/>
								<label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc mb-10" htmlFor="kt_create_account_form_account_type_personal">
									{/*begin::Svg Icon | path: icons/duotune/communication/com005.svg*/}
									<span className="svg-icon svg-icon-3x ">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z" fill="black" />
											<path opacity="0.3" d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}
									{/*begin::Info*/}
									<span className="d-block fw-bold text-start">
										<span className="text-dark fwt-bolder d-block fs-6 mb-2">Staff Member</span>
									</span>
									{/*end::Info*/}
								</label>
								{/*end::Option*/}
							</div>
							{/*end::Col*/}
							{/*begin::Col*/}
							<div className="col-lg-4">
								{/*begin::Option*/}
								<input type="radio" className="btn-check" name="userType" value="crew" id="kt_create_account_form_account_type_corporate"  checked={formData.userType === 'crew'} onChange={handleChange} />
								<label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc" htmlFor="kt_create_account_form_account_type_corporate">
									{/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
									<span className="svg-icon svg-icon-3x ">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black" />
											<path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}
									{/*begin::Info*/}
									<span className="d-block fw-bold text-start">
										<span className="text-dark fwt-bolder d-block fs-6 mb-2">Crew Member</span>
									</span>
									{/*end::Info*/}
								</label>
								{/*end::Option*/}
							</div>
							{/*end::Col*/}
							{/*begin::Col*/}
							<div className="col-lg-4">
								{/*begin::Option*/}
								<input type="radio" className="btn-check" name="userType" value="client" id="kt_create_account_form_account_type_corporate2"  checked={formData.userType === 'client'} onChange={handleChange} />
								<label className="btn btn-outline btn-outline-dashed btn-outline-default p-3 d-flex align-items-center f-dc" htmlFor="kt_create_account_form_account_type_corporate2">
									{/*begin::Svg Icon | path: icons/duotune/finance/fin006.svg*/}
									<span className="svg-icon svg-icon-3x">
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
											<path opacity="0.3" d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z" fill="black" />
											<path d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z" fill="black" />
										</svg>
									</span>
									{/*end::Svg Icon*/}
									{/*begin::Info*/}
									<span className="d-block fw-bold text-start">
										<span className="text-dark fwt-bolder d-block fs-6 mb-2">Beysix's Client</span>
									</span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="row fv-row mb-7">
					{/*begin::Col*/}
					<div className="col-xl-6">
						<label className="form-label fwt-bolder text-dark fs-6">First Name</label>
						<input className="form-control form-control-lg form-control-solid" ref={firstName}  onChange={handleChange} type="text" value={formData.firstName} placeholder="" name="firstName" autoComplete="off" />
						{errors.firstName && <div className="text-danger">{errors.firstName}</div>}
					</div>
					{/*end::Col*/}
					{/*begin::Col*/}
					<div className="col-xl-6">
						<label className="form-label fwt-bolder text-dark fs-6">Last Name</label>
						<input className="form-control form-control-lg form-control-solid" ref={lastName} onChange={handleChange}  type="text" placeholder="" value={formData.lastName} name="lastName" autoComplete="off" />
						{errors.lastName && <div className="text-danger">{errors.lastName}</div>}
					</div>
					{/*end::Col*/}
				</div>
				{/*end::Input group*/}
				<div className="row fv-row mb-7">
					{/*begin::Col*/}
					{formData.userType !== 'client' && <div className="col-xl-6">
						<label className="form-label fwt-bolder text-dark fs-6">Occupation (Not Mandatory)</label>
						<input className="form-control form-control-lg form-control-solid" ref={occupation}  onChange={handleChange} type="text" placeholder="" value={formData.occupation} name="occupation" autoComplete="off" />
						{errors.occupation && <div className="text-danger">{errors.occupation}</div>}
					</div>}
					<div className="col-xl-6">
						<label className="form-label fwt-bolder text-dark fs-6">Email</label>
						<input className="form-control form-control-lg form-control-solid" ref={userEmail} onChange={handleChange}  type="email" placeholder="" value={formData.email} name="email" autoComplete="off" />
						{errors.email && <div className="text-danger">{errors.email}</div>}
					</div>	
					{formData.userType === 'client' && 
					<div className="col-md-6 fv-row fv-plugins-icon-container">
						<label className="required fs-6 fwt-bold mb-2">Assign to client</label>
						<select aria-label="Select example" ref={client} id="dropdown" name="client" className='form-control form-control-solid' onChange={handleChange} value={formData.client}>
							<option value="0">-- Select Client --</option>
							{clients.map((client) => {return(<option value={client.id}>{client.name}</option>)})}
						</select>
						{errors.client && <div className="fv-plugins-message-container invalid-feedback"><div data-field="account_name" data-validator="notEmpty">{errors.client}</div></div>}
					</div>
					}
				</div>
				</div>
				{/*end::Wrapper*/}
			</div>
			{/*end::Step 1*/}
			{/*begin::Step 2*/}
			<div data-kt-stepper-element="content" className={step === 1 ? "current": null}>
				{/*begin::Wrapper*/}
				<div className="w-100">
					{/*begin::Heading*/}
					<div className="pb-4">
						{/*begin::Title*/}
						<h2 className="fw-bolder text-dark">Assign Roles and Permissions</h2>
						{/*end::Title*/}
						{/*begin::Notice*/}
						<div className="text-muted fw-bold fs-6">Assign roles and permissions to the user you wish to invite to enable/restrict access and functions.</div>
						{/*end::Notice*/}
					</div>
					{/*end::Heading*/}
					<div className="mb-7">
				{/*begin::Label*/}
				<label className="required fw-bold fs-6 mb-2">Role</label>
				{/*end::Label*/}
				{/*begin::Roles*/}
				{/*begin::Input row*/}
					{roles.length > 0 && 
					
					roles.map((role, index) => {
					return(
					<><div className="d-flex fv-row" style={{justifyContent: "space-between"}} key={index}>
						{/*begin::Radio*/}
						<div className="form-check form-check-custom form-check-solid">
							{/*begin::Input*/}
							<input
								style={{ height: "1.8em", width: "1.8em" }}
								className="form-check-input me-3"
								name="user_role"
								type="checkbox"
								checked={selectedRoles.includes(role.id)}
								onChange={() => handleCheckboxClick(role.id)}
							/>
							{/*end::Input*/}
							{/*begin::Label*/}
							<label className="form-check-label d-flex" htmlFor="kt_modal_update_role_option_0">
								<div>
									<div className="fw-bolder text-gray-800 fs-5">{role.name}</div>
								</div>
							</label>
							{/*end::Label*/}
						</div>
						{/*end::Radio*/}
						<div className="tool-tip">
							<i className="tool-tip__icon">i</i>
							<p className="tool-tip__info">
								{role.permissions.map((permission) =>
								 (
									<span className="info" style={{marginTop: "3px"}}><span className="info__title">x: </span>{permission.name}</span>
								 ))}
							</p>
						</div>
					</div>
					<div className="separator separator-dashed my-3"></div></>)})}

				</div>
				</div>
				{/*end::Wrapper*/}
			</div>
			{/*end::Step 2*/}
			{/*begin::Actions*/}
			<div className="d-flex flex-stack">
				<div className="mr-2">
				{step > 0 && 
				<button type="button" className="btn btn-lg btn-light-primary me-3" onClick={() => setStep(step - 1)}>
					<span className="svg-icon svg-icon-4 me-1">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
							<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
						</svg>
					</span>Back</button> }
				</div>
				<div>
					{ step === maxStep ? 
					<button type="button" className="btn btn-lg btn-beysix me-3" disabled={loading ? true : false} onClick={handleSubmit}>
						{!loading ?
						<span className="indicator-label">Submit
						<span className="svg-icon svg-icon-3 ms-2 me-0 svg-icon-white">
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
								<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
								<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
							</svg>
						</span>
						</span> :
						<span className="indicator-progress">Please wait...
						<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
					</button> :
					<button type="button" className="btn btn-lg btn-beysix" onClick={handleContinue}>Continue
					<span className="svg-icon svg-icon-4 ms-1 me-0">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
							<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
							<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
						</svg>
					</span>
				</button>}
				</div>
				{/*end::Wrapper*/}
			</div>
			{/*end::Actions*/}
		<div>
			</div>
			<div>
				</div>
				<div>
					</div>
					<div>
						</div>
						</form>
		{/*end::Form*/}
	</div>
	</div>
    );
}
