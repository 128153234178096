import React, {useState, useEffect} from 'react'
import UserMultipleSearchComponent from '../../Utilities/UserMultipleSearchComponent'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'

export default function ConfigureCrew({handleUpdateCrew}) {

    const [selectedCrew, setSelectedCrew] = useState([])
    const [successfullSubmission, setSuccessfullSubmission] = useState(false)
    const [teamLeaders, setTeamLeaders] = useState([])
    const [removedTeamLeaders, setRemovedTeamLeaders] = useState([])
    const [removedCrew, setRemovedCrew] = useState([])

    const toggleTeamLeader = (crewId) => {
        const index = teamLeaders.indexOf(crewId);
        if (index === -1) {
            setTeamLeaders([...teamLeaders, crewId]);
            const removedIndex = removedTeamLeaders.indexOf(crewId);
            if (removedIndex !== -1) {
                setRemovedTeamLeaders(prevRemovedTeamLeaders => prevRemovedTeamLeaders.filter(id => id !== crewId));
            }
        } else {
            setTeamLeaders(teamLeaders.filter(id => id !== crewId));
            setRemovedTeamLeaders([...removedTeamLeaders, crewId])
        }
    };

    const {id} = useParams()

    const token = useSelector((state) => state.loginSlice.token);
    
    const [loading, setLoading] = useState(false)

    const [seniorTeamLeader, setSeniorTeamLeader] = useState(0)

    const handleRemoveCrew = (indexToRemove, crewId) => {
        
        selectedCrew.splice(indexToRemove, 1)
        setSelectedCrew(selectedCrew)
        setTeamLeaders(teamLeaders.filter(id => id !== crewId));
        setRemovedCrew([...removedCrew, crewId])

        // check if is in removedTeamLeaders then filter crew out
        let removedIndex = removedTeamLeaders.findIndex(id => id === crewId);
        if (removedIndex !== -1) 
            setRemovedTeamLeaders(prevRemovedTeamLeaders => prevRemovedTeamLeaders.filter(id => id !== crewId));
        
        // check if is a teamleader then add to removedTeamLeaders
        removedIndex = teamLeaders.findIndex(id => id === crewId);
        if (removedIndex !== -1)
            setRemovedTeamLeaders([...removedTeamLeaders, crewId])
        
        if (parseInt(crewId) === parseInt(seniorTeamLeader))
            setSeniorTeamLeader(0)
    
    }

    const getExistingCrewMembers = () => {
        api.get(`/api/activations/configure-crew/${id}/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data.crew_data[0]
                    if (data){
                        setSelectedCrew(data.crew_members)
                        
                        if (data.senior_team_leader)
                            setSeniorTeamLeader(data.senior_team_leader.id)
                        
                        const team_leader_ids = data.team_leaders.map(item => item.id);
                        setTeamLeaders(team_leader_ids)
                    }
                }
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
    }

    useEffect(() => {
        getExistingCrewMembers()
    }, [])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        setLoading(true)
        if (selectedCrew.length > 0) {
            const data = {
                'selectedCrew': selectedCrew, 
                'teamLeaders': teamLeaders, 
                'seniorTeamLeader': seniorTeamLeader,
                'removedTeamLeaders': removedTeamLeaders,
                'removedCrew': removedCrew
            }
            api.post(`/api/activations/configure-crew/${id}/`, data,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        const data = response.data.crew_data
                        setLoading(false)
                        if (data !== null || data !== undefined)
                            handleUpdateCrew(data, response.data.message)
                      } 
                })
              .catch((error) => {
                setLoading(false)
                setRes({
                    message: error.response.data.error,
                    severity: "error",
                    show: true
                })
            })
        }
    }

  return (
    <div className="w-100">
        {/*begin::Heading*/}
        <div className="pb-2 pb-lg-2">
            <div className="text-muted ftw-bold fs-6">Configure the crew involved with this activation</div>
            {/*end::Notice*/}
        </div>
        {/*end::Heading*/}
        
        <UserMultipleSearchComponent  returnResults = {
            (result) => {
                const index = selectedCrew.findIndex(crew => crew.id === result.id);  
                if (index === -1) {
                    setSelectedCrew(prevSelectedCrew => [...prevSelectedCrew, result]);
                } else {
                    setSelectedCrew(prevSelectedCrew => prevSelectedCrew.filter(crew => crew.id !== result.id));
                    setTeamLeaders(prevTeamLeaders => prevTeamLeaders.filter(id => id !== result.id));
                }

                const removedIndex = removedCrew.findIndex(crewId => crewId === result.id);
                if (removedIndex !== -1) {
                    setRemovedCrew(prevRemovedCrew => prevRemovedCrew.filter(crewId => crewId !== result.id));
                } 
            }
        } successfullSubmission={successfullSubmission} userType={'crew'} inputName={''} selectedUsers={selectedCrew.map(crew => crew.id)} />
        <div style={{maxHeight: '350px', overflow: 'auto', marginTop: '50px'}}>
            {selectedCrew.map((crew, index) => <div className="alert alert-beysix mb-2">
                <div className="crew-selection d-flex justify-content-between" key={index}>
                    <div className="d-flex">
                    {crew.profile && crew.profile.profile_photo_path ?
                        <div className='asset-item-image-wrapper'>
                            <img className='asset-item-image' src={item.first_image && item.first_image.image_url} alt={item.name} />
                        </div> :
                        <div className='item-initial' style={{width: '50px', height: '50px'}}>
                            {crew.profile && crew.profile.first_name ? <span style={{fontSize: '25px'}}>{crew.profile.first_name[0]}</span>: <span style={{fontSize: '25px'}}>A</span>}
                        </div>}
                        <div className="d-flex flex-column" style={{justifyContent: 'center', maxWidth: '75%', textAlign: 'justify'}}>
                            {crew.profile && crew.profile.first_name ? <h5 className="mb-1">{crew.profile.first_name} {crew.profile.last_name}</h5> : <h5 className="mb-1">No name</h5>}
                            <span>{crew.email}</span>
                        </div>
                    </div>
                    <div style={{position: 'relative', display: 'flex', alignItems: 'center'}}>
                        {teamLeaders.length > 1 && 
                        <div className={seniorTeamLeader === 0 ? 'crew-action-icon senior-team-leader' : 'crew-action-icon'} title={`Make ${crew.profile && crew.profile.first_name} A Senior Team Leader.`}>
                            {/* bi-star-fill */}
                            {seniorTeamLeader === 0 && <span className='bi bi-shield fs-4 cancel-icon' onClick={() => setSeniorTeamLeader(crew.id)}></span>}
                            <span className={seniorTeamLeader === crew.id && 'bi bi-shield-fill fs-4 cancel-icon'} style={{display: 'flex'}} onClick={() => {
                               setSeniorTeamLeader(0)
                            }}></span>
                        </div>}
                        <div className='crew-action-icon' title={`Make ${crew.profile && crew.profile.first_name} A Team Leader.`}>
                            {/* bi-star-fill */}
                            <span className={!teamLeaders.includes(crew.id) ? 'bi bi-star fs-3 cancel-icon' : 'bi bi-star-fill fs-3 cancel-icon'} onClick={() => toggleTeamLeader(crew.id)}></span>
                        </div>
                        <div className='crew-action-icon'>
                            <span className='bi bi-x fs-3 cancel-icon' title={`Remove ${crew.profile && crew.profile.first_name} From Crew.`} onClick={() => handleRemoveCrew(index, crew.id)}></span>
                        </div>
                    </div>
            </div>
        </div>)}
    </div>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}}>    
        <button onClick={handleSubmit} disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-sm btn-beysix fwt-bolder">
            {!loading && <span className="indicator-label">Submit</span>}
            {loading && <span className="indicator-progress">Please wait...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
        </button>
    </div>
</div>
  )
}
