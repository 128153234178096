import React, {useState, useEffect} from 'react'
import api from '../../store/api';
import { useSelector } from 'react-redux';
import getRandomHexColor from './getRandomHexColor';


export default function UserMultipleSearchComponent({returnResults, userType, successfullSubmission, selectedUsers, inputName, edit=null, userName=null, occupation=null}) {

    const token = useSelector((state) => state.loginSlice.token);
  const [searchQuery, setSearchQuery] = useState("")
  const [searchResults, setSearchResults] = useState([])
  const [isSearching, setIsSearching] = useState(false)
  const [userSearching, setUserSearching] = useState(false)
  const [resultsFound, setResultsFound] = useState(false)

  useEffect(() => {
        if (successfullSubmission === true) {
            setResultsFound(false)
            setIsSearching(false)
            setUserSearching(false)
            setSearchResults([])
            setResultsFound(false)
            setSearchQuery("")
            returnResults(null)
        }
    }, [successfullSubmission])

    useEffect(() => {
        if (userName)
            setSearchQuery(userName)
    }, [edit])
  
  const handleSearch = (evt) => {
    evt.preventDefault()
    const query = evt.target.value;
        setSearchQuery(query)
        if (query.length >= 2) {
            setUserSearching(true)
            setIsSearching(true)
            let url = `/api/users/search-user/${userType}/`
            if (occupation !== null) {
                url = `/api/users/search-user/${userType}/${occupation}/`
            }
            
            api.post(url, {query: query},
            {headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${token}`,
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    if (response.data.results.length > 0){
                        setResultsFound(true)
                        setSearchResults(response.data.results)
                        setIsSearching(false)
                    } else {
                        setResultsFound(false)
                        setSearchResults(response.data.results)
                        setIsSearching(false)
                    }
                }
            })
            .catch((error) => {
                if (response.status === 404) {

                } else {
                    throw new Error(error)
                }
            })
        } else if (query.length === 0) {
            setResultsFound(false)
            setIsSearching(false)
            setUserSearching(false)
        }
  }

  const handleCancelSearch = (evt) => {
      evt.preventDefault()
      setResultsFound(false)
      setIsSearching(false)
      setUserSearching(false)
      setSearchResults([])
      setResultsFound(false)
      setSearchQuery("")
  }

  const handleManagerClick = (result) => {
    setIsSearching(true)
    setUserSearching(true)
    setResultsFound(true)
    returnResults(result)
}


    return (
        <>
        <label className="form-label">{inputName}</label>
        <div>
        <div>
            {/*begin::Search*/}
            <div id="kt_docs_search_handler_basic" data-kt-search-keypress="true" data-kt-search-min-length="2" data-kt-search-enter="true" data-kt-search-layout="inline" data-kt-search="true" className="">
                <input type="hidden" />
                <div style={{position: 'relative'}}>
                <span className="svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500" style={{position: "absolute", left: "2%", top: '25%'}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
                    </svg>
                </span>
                    <div className='row'>
                        <div className='col-md-11'>
                            <input type="text" autoComplete="off" className="form-control form-control-lg form-control-solid px-15" name="search" value={searchQuery} placeholder="Search by username, full name or email..." onChange={handleSearch} />
                        </div>
                        <div className='col-md-1'>
                            <span className="add-item-btn" onClick={() => {
                                
                                setIsSearching(false)
                                setUserSearching(false)
                                setResultsFound(false)
                                setSearchQuery("")

                            }} style={{background: '#d9f4e6'}}><i className="bi bi-check" style={{fontSize: "2.2rem", color: '#50cd89'}}></i></span>
                        </div>
                    </div>
                    {isSearching ? <span className="lh-0 me-5" data-kt-search-element="spinner" style={{position: "absolute", top: "35%", left: "88%"}}>
                        <span className="spinner-border h-15px w-15px align-middle text-gray-400"></span>
                    </span> :
                    searchQuery.length > 0 && 
                    <span className="btn btn-flush btn-active-color-primary end-0 lh-0" style={{position: "absolute", top: "25%", left: "93%"}} data-kt-search-element="clear" onClick={handleCancelSearch}>
                        <span className="svg-icon svg-icon-2 svg-icon-lg-1">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="black"></rect>
                                <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="black"></rect>
                            </svg>
                        </span>
                    </span>}
            {userSearching && 
            <div className="quick-search-dropdown" style={{position: "absolute", minWidth: '91.5%'}}>
                {searchResults.length > 0 && resultsFound ? <div data-kt-search-element="results" className="">
                {searchResults.map((result, index) => {
                const { randomColor, darkerShade, brighterShade } = getRandomHexColor();
                return (
                <div className={selectedUsers.includes(result.id) ? "d-flex align-items-center p-3 rounded-3 active-orange cursor-pointer mb-1" : "d-flex align-items-center p-3 rounded-3 border-hover border border-dashed border-gray-300 cursor-pointer mb-1"} key = {index} onClick={() => handleManagerClick(result)}>
                    <div className="symbol symbol-35px symbol-circle me-5">
                        {result.profile && (result.profile.profile_photo_path !== "" && result.profile.profile_photo_path !== null) ?
                        <img alt="Pic" src={result.profile.profile_photo_path} style={{objectFit: "cover"}}/> :
                        result.profile && <div className="symbol-label fs-5" style={{ background: brighterShade, color: darkerShade }}>{result.profile.first_name[0]}{result.profile.last_name[0]}</div>}
                    </div>
                    <div className="fw-bold">
                        <span className="fs-6 text-gray-800 me-2">{result.profile && `${result.profile.first_name} ${result.profile.last_name}`}</span>
                        <span className="badge badge-light">{result.profile && result.profile.occupation}</span>
                    </div>
                </div>)})}
                </div> :
                <div data-kt-search-element="empty" className="text-center">
                    {/*begin::Message*/}
                    <div className="fwt-bold py-0 mb-10">
                        <div className="text-gray-600 fs-3 mb-2">No users found</div>
                        <div className="text-gray-400 fs-6">Try to search by username, full name or email...</div>
                    </div>
                    {/*end::Message*/}
                    {/*begin::Illustration*/}
                    <div className="text-center px-4">
                        <img className="mw-100 mh-200px" alt="" src="assets/media/illustrations/alert.png" />
                    </div>
                    {/*end::Illustration*/}
                </div>}
                {/*end::Empty*/}
            </div>}
            {/*end::Wrapper*/}
                        </div>
                        {/*end::Search*/}
                        </div>
                    </div>
                    </div>
                    </>
  )
}
