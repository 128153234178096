import React, {useRef, useEffect, useState} from 'react'
import parseDateTimeStamp from '../../Utilities/parseDateTimeStamp.'
import { Link } from 'react-router-dom';
import SkeletonLoader from '../../Warehouse/SkeletonLoader/SkeletonLoader';
import { motion } from 'framer-motion';
import MainModal from '../../Modals/MainModal';
import ShowModalData from '../ShowModalData/ShowModalData';

export default function LocationsTable({locationData, pageLoading, resultsFound,}) {
    const tableRef = useRef(null);
    const [activeTab, setActiveTab] = useState('')
    const [modalTitle, setModalTitle] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemList, setItemList] = useState([])
    const [url, setUrl] = useState('')
  return (
    <>
    {!pageLoading ? 
        resultsFound &&
        <div className="table-responsive"
            ref={tableRef}
            style={{ height: '100vh', overflowY: 'auto' }}
        >
        <MainModal 
        handleClose={() => {
            setModalIsOpen(false)
            setItemList([])
        }} 
        show={modalIsOpen}
        modalTitle={modalTitle}>
            <ShowModalData data={itemList} url={url} />
        </MainModal>
        {/*begin::Table*/}
        <motion.table
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.3 }}
            className="table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 p-5"
        >
        {/*begin::Table head*/}
        <thead>
        <tr className="fwt-bold bg-light">
            <th className="ps-4 min-w-300px rounded-start">Location Name</th>
            <th className="min-w-125px">Count</th>
            <th className="min-w-125px">Activation(s)</th>
            <th className="min-w-100px">Activating Agencies</th>
            <th className="min-w-125px">Brand(s)</th>
            {/* <th className="min-w-125px">Project(s)</th> */}
            <th className="min-w-100px">Client(s)</th>
            <th className="min-w-100px">Commissioning Agencies</th>
        </tr>
        </thead>
        {/*end::Table head*/}
        {/*begin::Table body*/}
        <tbody>
    {Object.entries(locationData).map(([location, data]) => {
    return (<tr key={location}>
            <td>
                <div className="d-flex align-items-center">
                <div className="symbol symbol-50px me-3">
                    <span className="symbol-label bg-light">
                        <span className="svg-icon svg-icon-3x">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"></path><path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"></path></svg>
                        </span>
                    </span>
                    </div>
                    <a className="text-dark fwt-bold d-block mb-1 fs-6">{location}</a>
                </div>
            </td>
            <td>
                <a  className="text-dark fwt-bold d-block mb-1 fs-6">{data.count} {data.count > 1 ? 'activations' : 'activation'}</a>
            </td>
            <td>
            <div className="d-flex justify-content-start flex-column">
                {data.activations.length > 1 ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6"
                    onClick={() => {
                        setItemList(data.activations)
                        setUrl('/activation-management/detailed-activation/')
                        setModalTitle(`Activations in ${location}`)
                        setModalIsOpen(true)
                    }}
                >{data.activations[0].name} <span style={{color: '#009ef7'}}>+{data.activations.length - 1} More</span></a> :
                data.activations.length > 0 ? <Link to={`/activation-management/detailed-activation/${data.activations[0].id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{data.activations[0].name}</Link> : <a className="text-dark fwt-bold d-block mb-1 fs-6">Not Added</a>}
                </div>
            </td>
            <td>
            <div className="d-flex justify-content-start flex-column">
                {data.activating_agencies.length > 1 ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6"
                    onClick={() => {
                        setItemList(data.activating_agencies)
                        setUrl('/agency-management/agencies/detailed-agency/')
                        setModalTitle(`Activation Agencies in ${location}`)
                        setModalIsOpen(true)
                    }}
                >{data.activating_agencies[0].name} <span style={{color: '#009ef7'}}>+{data.activating_agencies.length - 1} More</span></a> :
                data.activating_agencies.length > 0 ? <Link to={`/agency-management/agencies/detailed-agency/${data.activating_agencies[0].id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{data.activating_agencies[0].name}</Link> : <a className="text-dark fwt-bold d-block mb-1 fs-6">Not Added</a>}
                </div>
            </td>
            <td>
            <div className="d-flex justify-content-start flex-column">
                {data.brands.length > 1 ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6"
                    onClick={() => {
                        setItemList(data.brands)
                        setUrl('/brand-management/detailed-brand/')
                        setModalTitle(`Brands in ${location}`)
                        setModalIsOpen(true)
                    }}
                >{data.brands[0].name} <span style={{color: '#009ef7'}}>+{data.brands.length - 1} More</span></a> :
                data.brands.length > 0 ? <Link to={`/brand-management/detailed-brand/${data.brands[0].id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{data.brands[0].name}</Link> : <a className="text-dark fwt-bold d-block mb-1 fs-6">Not Added</a>}
                </div>
            </td>
            {/* <td>
            <div className="d-flex justify-content-start flex-column">
                {data.projects.length > 1 ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6"
                    onClick={() => {
                        setItemList(data.projects)
                        setUrl('/project-management/detailed-project/')
                        setModalTitle(`Projects in ${location}`)
                        setModalIsOpen(true)
                    }}
                >{data.projects[0].name} <span style={{color: '#009ef7'}}>+{data.projects.length - 1} More</span></a> :
                data.projects.length > 0 ? <Link to={`/project-management/detailed-project/${data.projects[0].id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{data.projects[0].name}</Link> : <a className="text-dark fwt-bold d-block mb-1 fs-6">Not Added</a>}
                </div>
            </td> */}
            <td>
            <div className="d-flex justify-content-start flex-column">
                {data.clients.length > 1 ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6"
                    onClick={() => {
                        setItemList(data.clients)
                        setUrl('/client-management/clients/detailed-client-organization/')
                        setModalTitle(`Clients in ${location}`)
                        setModalIsOpen(true)
                    }}
                >{data.clients[0].name} <span style={{color: '#009ef7'}}>+{data.clients.length - 1} More</span></a> :
                data.projects.length > 0 ? <Link to={`/client-management/clients/detailed-client-organization/${data.clients[0].id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{data.clients[0].name}</Link> : <a className="text-dark fwt-bold d-block mb-1 fs-6">Not Added</a>}
                </div>
            </td>
            <td>
            <div className="d-flex justify-content-start flex-column">
                {data.agencies.length > 1 ?
                <a className="text-dark fwt-bold d-block mb-1 fs-6"
                    onClick={() => {
                        setItemList(data.agencies)
                        setUrl('/agency-management/agencies/detailed-agency/')
                        setModalTitle(`Agencies in ${location}`)
                        setModalIsOpen(true)
                    }}
                >{data.agencies[0].name} <span style={{color: '#009ef7'}}>+{data.agencies.length - 1} More</span></a> :
                data.projects.length > 0 ? <Link to={`/agency-management/agencies/detailed-agency/${data.agencies[0].id}`} className="text-dark fwt-bold text-hover-primary mb-1 fs-6">{data.agencies[0].name}</Link> : <a className="text-dark fwt-bold d-block mb-1 fs-6">Not Added</a>}
                </div>
            </td>
            </tr>)
                })}
            </tbody>
                    {/*end::Table body*/}
                </motion.table>
                {/*end::Table*/}
            </div> :
            <SkeletonLoader />}
        </>
  )
}
