import React, { useState, useEffect } from 'react';
import api from '../../store/api';
import { useSelector, useDispatch } from 'react-redux';
import { resetLoginState, setRedirectPath } from '../../store/loginSlice';
import RedirectHandler from '../RedirectHandler/RedirectHandler'
import { NavLink } from 'react-router-dom';
import findSetting from '../Utilities/findSetting';
import { updateUserSettings } from '../../store/loginSlice';

export default function StaffAside() {
    
    const permissions = useSelector((state) => state.loginSlice.permissions);
  
return (
    <div className="hover-scroll-overlay-y my-5 my-lg-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer" data-kt-scroll-wrappers="#kt_aside_menu" data-kt-scroll-offset="0" style={{height: "598px"}}>
        {/*begin::Menu*/}
        <div className="menu menu-column menu-title-gray-800 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500" id="#kt_aside_menu" data-kt-menu="true">
            <div className="menu-item">
                <div className="menu-content pt-3 pb-2">
                    <span className="menu-section text-muted text-uppercase fs-8 ls-1">Apps</span>
                </div>
            </div>
            {(	permissions["view_client_profile"] || 
                permissions["view_staff_profile"] || 
                permissions["view_crew_profile"] || 
                permissions["can_approve_user"] ||
                permissions["can_invite_user"] || 
                permissions["view_group"]) &&
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion mb-1">
                <span className="menu-link">
                    <span className="menu-icon">
                        {/*begin::Svg Icon | path: icons/duotune/general/gen051.svg*/}
                        <span className="svg-icon svg-icon-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path opacity="0.3" d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z" fill="black"></path>
                                <path d="M14.854 11.321C14.7568 11.2282 14.6388 11.1818 14.4998 11.1818H14.3333V10.2272C14.3333 9.61741 14.1041 9.09378 13.6458 8.65628C13.1875 8.21876 12.639 8 12 8C11.361 8 10.8124 8.21876 10.3541 8.65626C9.89574 9.09378 9.66663 9.61739 9.66663 10.2272V11.1818H9.49999C9.36115 11.1818 9.24306 11.2282 9.14583 11.321C9.0486 11.4138 9 11.5265 9 11.6591V14.5227C9 14.6553 9.04862 14.768 9.14583 14.8609C9.24306 14.9536 9.36115 15 9.49999 15H14.5C14.6389 15 14.7569 14.9536 14.8542 14.8609C14.9513 14.768 15 14.6553 15 14.5227V11.6591C15.0001 11.5265 14.9513 11.4138 14.854 11.321ZM13.3333 11.1818H10.6666V10.2272C10.6666 9.87594 10.7969 9.57597 11.0573 9.32743C11.3177 9.07886 11.6319 8.9546 12 8.9546C12.3681 8.9546 12.6823 9.07884 12.9427 9.32743C13.2031 9.57595 13.3333 9.87594 13.3333 10.2272V11.1818Z" fill="black"></path>
                            </svg>
                        </span>
                        {/*end::Svg Icon*/}
                    </span>
                    <span className="menu-title">User Management</span>
                    <span className="menu-arrow"></span>
                </span>
                <div className="menu-sub menu-sub-accordion">
                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion mb-1">
                        {	(permissions["view_client_profile"] || 
                            permissions["view_staff_profile"] || 
                            permissions["view_crew_profile"] || 
                            permissions["can_approve_user"] ||
                            permissions["can_invite_user"]) &&
                            <span className="menu-link">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Users</span>
                            <span className="menu-arrow"></span>
                        </span>}
                        <div className="menu-sub menu-sub-accordion">
                            {permissions["view_client_profile"] && <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/user-management/users/clients">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Client List</span>
                                </NavLink>
                            </div>}
                            {permissions["view_staff_profile"] &&<div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/user-management/users/staff">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Staff Members</span>
                                </NavLink>
                            </div>}
                            {permissions["view_crew_profile"] && <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/user-management/users/crew">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Crew Members</span>
                                </NavLink>
                            </div>}
                            {permissions["can_approve_user"] && <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/user-management/users/unapproved">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Unapproved Users</span>
                                </NavLink>
                            </div>}
                            {permissions["can_invite_user"] && <div className="menu-item">
                                <NavLink className="menu-link" to="/user-management/invite-user">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Invite User</span>
                                </NavLink>
                            </div>}
                        </div>
                    </div>
                    {permissions["view_group"] &&<div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                        <span className="menu-link">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Roles</span>
                            <span className="menu-arrow"></span>
                        </span>
                        {permissions["view_group"] &&
                        <div className="menu-sub menu-sub-accordion">
                            <div className="menu-item">
                                <NavLink className="menu-link" to="/user-management/roles">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Roles List</span>
                                </NavLink>
                            </div>
                            {/* <div className="menu-item">
                                <a className="menu-link" href="/user-management/roles">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">View Role</span>
                                </a>
                            </div> */}
                        </div>}
                    </div>}
                </div>
            </div>}
            {permissions["view_warehouse"] &&
            <div className="menu-item">
                <NavLink className="menu-link" to="/warehouse-management/warehouses">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M11 2.375L2 9.575V20.575C2 21.175 2.4 21.575 3 21.575H9C9.6 21.575 10 21.175 10 20.575V14.575C10 13.975 10.4 13.575 11 13.575H13C13.6 13.575 14 13.975 14 14.575V20.575C14 21.175 14.4 21.575 15 21.575H21C21.6 21.575 22 21.175 22 20.575V9.575L13 2.375C12.4 1.875 11.6 1.875 11 2.375Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Warehouse Management</span>
                </NavLink>
            </div>}
            {permissions["view_agency"] &&
            <div className="menu-item">
                <NavLink className="menu-link" to="/client-management/clients">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M7.16973 20.95C6.26973 21.55 5.16972 20.75 5.46972 19.75L7.36973 14.05L2.46972 10.55C1.56972 9.95005 2.06973 8.55005 3.06973 8.55005H20.8697C21.9697 8.55005 22.3697 9.95005 21.4697 10.55L7.16973 20.95Z" fill="black"/>
                    <path d="M11.0697 2.75L7.46973 13.95L16.9697 20.85C17.8697 21.45 18.9697 20.65 18.6697 19.65L13.1697 2.75C12.7697 1.75 11.3697 1.75 11.0697 2.75Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Client Management</span>
                </NavLink>
            </div>}
            {permissions["view_agency"] &&
            <div className="menu-item">
                <NavLink className="menu-link" to="/agency-management/agencies">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.925 3.90078V8.00077L12.025 10.8008V5.10078L15.525 3.10078C16.125 2.80078 16.925 3.20078 16.925 3.90078ZM2.525 13.5008L6.025 15.5008L10.925 12.7008L6.025 9.90078L2.525 11.9008C1.825 12.3008 1.825 13.2008 2.525 13.5008ZM18.025 19.7008V15.6008L13.125 12.8008V18.5008L16.625 20.5008C17.225 20.8008 18.025 20.4008 18.025 19.7008Z" fill="black"/>
                    <path opacity="0.3" d="M8.52499 3.10078L12.025 5.10078V10.8008L7.125 8.00077V3.90078C7.125 3.20078 7.92499 2.80078 8.52499 3.10078ZM7.42499 20.5008L10.925 18.5008V12.8008L6.02499 15.6008V19.7008C6.02499 20.4008 6.82499 20.8008 7.42499 20.5008ZM21.525 11.9008L18.025 9.90078L13.125 12.7008L18.025 15.5008L21.525 13.5008C22.225 13.2008 22.225 12.3008 21.525 11.9008Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Agency Management</span>
                </NavLink>
            </div>}
            {permissions["view_brand"] &&
            <div className="menu-item">
                <NavLink className="menu-link" to="/brand-management/brands">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M16.0077 19.2901L12.9293 17.5311C12.3487 17.1993 11.6407 17.1796 11.0426 17.4787L6.89443 19.5528C5.56462 20.2177 4 19.2507 4 17.7639V5C4 3.89543 4.89543 3 6 3H17C18.1046 3 19 3.89543 19 5V17.5536C19 19.0893 17.341 20.052 16.0077 19.2901Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Brand Management</span>
                </NavLink>
            </div>}
            {permissions["view_project"] &&
            <div className="menu-item">
                <NavLink className="menu-link" to="/project-management/projects">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path opacity="0.3" d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z" fill="black"/>
                    <path d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Project Management</span>
                </NavLink>
            </div>}
            {(permissions["view_tool"] || permissions["view_accessory"]) &&
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                <span className="menu-link">
                    <span className="menu-icon">
                        <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path opacity="0.3" d="M22.0318 8.59998C22.0318 10.4 21.4318 12.2 20.0318 13.5C18.4318 15.1 16.3318 15.7 14.2318 15.4C13.3318 15.3 12.3318 15.6 11.7318 16.3L6.93177 21.1C5.73177 22.3 3.83179 22.2 2.73179 21C1.63179 19.8 1.83177 18 2.93177 16.9L7.53178 12.3C8.23178 11.6 8.53177 10.7 8.43177 9.80005C8.13177 7.80005 8.73176 5.6 10.3318 4C11.7318 2.6 13.5318 2 15.2318 2C16.1318 2 16.6318 3.20005 15.9318 3.80005L13.0318 6.70007C12.5318 7.20007 12.4318 7.9 12.7318 8.5C13.3318 9.7 14.2318 10.6001 15.4318 11.2001C16.0318 11.5001 16.7318 11.3 17.2318 10.9L20.1318 8C20.8318 7.2 22.0318 7.59998 22.0318 8.59998Z" fill="black"/>
                        <path d="M4.23179 19.7C3.83179 19.3 3.83179 18.7 4.23179 18.3L9.73179 12.8C10.1318 12.4 10.7318 12.4 11.1318 12.8C11.5318 13.2 11.5318 13.8 11.1318 14.2L5.63179 19.7C5.23179 20.1 4.53179 20.1 4.23179 19.7Z" fill="black"/>
                        </svg></span>
                    </span>
                    <span className="menu-title">Tools And Accessories</span>
                    <span className="menu-arrow"></span>
                </span>
                <div className="menu-sub menu-sub-accordion" kt-hidden-height="117" style={{display: "none", overflow: "hidden"}}>
                {permissions["view_tool"] &&
                    <div className="menu-item">
                        <NavLink className="menu-link" activeClassName = "active" to="/tool-management/tools">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Tool Management</span>
                        </NavLink>
                    </div>}
                    {permissions["view_accessory"] &&
                    <div className="menu-item">
                        <NavLink className="menu-link" activeClassName = "active" to="/tool-management/accessories">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Accessory Management</span>
                        </NavLink>
                    </div>}
                </div>
            </div>}
            {(permissions["view_asset"] || 
                permissions["add_asset"] || permissions["view_material"] ||  
                permissions["add_item"] || permissions["view_item"]) &&
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                <span className="menu-link">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M22 19V17C22 16.4 21.6 16 21 16H8V3C8 2.4 7.6 2 7 2H5C4.4 2 4 2.4 4 3V19C4 19.6 4.4 20 5 20H21C21.6 20 22 19.6 22 19Z" fill="black"/>
                    <path d="M20 5V21C20 21.6 19.6 22 19 22H17C16.4 22 16 21.6 16 21V8H8V4H19C19.6 4 20 4.4 20 5ZM3 8H4V4H3C2.4 4 2 4.4 2 5V7C2 7.6 2.4 8 3 8Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Asset Management</span>
                    <span className="menu-arrow"></span>
                </span>
                <div className="menu-sub menu-sub-accordion" kt-hidden-height="117" style={{display: "none", overflow: "hidden"}}>
                {permissions["view_material"] &&
                    <div className="menu-item">
                        <NavLink className="menu-link" activeClassName = "active" to="/asset-management/materials">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Materials</span>
                        </NavLink>
                    </div>}
                    {(permissions["view_asset"] || permissions["add_asset"]) &&
                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                        <span className="menu-link">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Assets</span>
                            <span className="menu-arrow"></span>
                        </span>
                        <div className="menu-sub menu-sub-accordion" kt-hidden-height="78" style={{display: "none", overflow: "hidden"}}>
                            {permissions["view_asset"] &&
                            <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/asset-management/assets">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Assets List</span>
                                </NavLink>
                            </div>}
                            {permissions["add_asset"] &&
                            <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/asset-management/create-asset">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Create Asset</span>
                                </NavLink>
                            </div>}
                        </div>
                    </div>}
                    {(permissions["view_item"] || permissions['add_item']) &&
                    <div data-kt-menu-trigger="click" className="menu-item menu-accordion">
                        <span className="menu-link">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Items</span>
                            <span className="menu-arrow"></span>
                        </span>
                        <div className="menu-sub menu-sub-accordion" kt-hidden-height="78" style={{display: "none", overflow: "hidden"}}>
                            {permissions["view_item"] &&
                            <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/asset-management/items">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Items List</span>
                                </NavLink>
                            </div>}
                            {permissions["add_item"] &&
                            <div className="menu-item">
                                <NavLink className="menu-link" activeClassName = "active" to="/asset-management/create-item">
                                    <span className="menu-bullet">
                                        <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">Create Item</span>
                                </NavLink>
                            </div>}
                        </div>
                    </div>}
                </div>
            </div>}
            <div className="menu-item">
                <div className="menu-content pt-3 pb-2">
                    <span className="menu-section text-muted text-uppercase fs-8 ls-1">Operations</span>
                </div>
            </div>
            <div data-kt-menu-trigger="click" className="menu-item menu-accordion mb-1">
                <span className="menu-link">
                    <span className="menu-icon">
                    <span className="svg-icon svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12.6 7C12 7 11.6 6.6 11.6 6V3C11.6 2.4 12 2 12.6 2C13.2 2 13.6 2.4 13.6 3V6C13.6 6.6 13.2 7 12.6 7ZM10 7.59998C10.5 7.29998 10.6 6.69995 10.4 6.19995L9 3.80005C8.7 3.30005 8.10001 3.20002 7.60001 3.40002C7.10001 3.70002 7.00001 4.30005 7.20001 4.80005L8.60001 7.19995C8.80001 7.49995 9.1 7.69995 9.5 7.69995C9.7 7.69995 9.9 7.69998 10 7.59998ZM8 9.30005C8.3 8.80005 8.10001 8.20002 7.60001 7.90002L5.5 6.69995C5 6.39995 4.40001 6.59998 4.10001 7.09998C3.80001 7.59998 4 8.2 4.5 8.5L6.60001 9.69995C6.80001 9.79995 6.90001 9.80005 7.10001 9.80005C7.50001 9.80005 7.9 9.70005 8 9.30005ZM7.20001 12C7.20001 11.4 6.80001 11 6.20001 11H4C3.4 11 3 11.4 3 12C3 12.6 3.4 13 4 13H6.20001C6.70001 13 7.20001 12.6 7.20001 12Z" fill="black"/>
                    <path opacity="0.3" d="M17.4 5.5C17.4 6.1 17 6.5 16.4 6.5C15.8 6.5 15.4 6.1 15.4 5.5C15.4 4.9 15.8 4.5 16.4 4.5C17 4.5 17.4 5 17.4 5.5ZM5.80001 17.1L7.40001 16.1C7.90001 15.8 8.00001 15.2 7.80001 14.7C7.50001 14.2 6.90001 14.1 6.40001 14.3L4.80001 15.3C4.30001 15.6 4.20001 16.2 4.40001 16.7C4.60001 17 4.90001 17.2 5.30001 17.2C5.50001 17.3 5.60001 17.2 5.80001 17.1ZM8.40001 20.2C8.20001 20.2 8.10001 20.2 7.90001 20.1C7.40001 19.8 7.3 19.2 7.5 18.7L8.30001 17.3C8.60001 16.8 9.20002 16.7 9.70002 16.9C10.2 17.2 10.3 17.8 10.1 18.3L9.30001 19.7C9.10001 20 8.70001 20.2 8.40001 20.2ZM12.6 21.2C12 21.2 11.6 20.8 11.6 20.2V18.8C11.6 18.2 12 17.8 12.6 17.8C13.2 17.8 13.6 18.2 13.6 18.8V20.2C13.6 20.7 13.2 21.2 12.6 21.2ZM16.7 19.9C16.4 19.9 16 19.7 15.8 19.4L15.2 18.5C14.9 18 15.1 17.4 15.6 17.1C16.1 16.8 16.7 17 17 17.5L17.6 18.4C17.9 18.9 17.7 19.5 17.2 19.8C17 19.9 16.8 19.9 16.7 19.9ZM19.4 17C19.2 17 19.1 17 18.9 16.9L18.2 16.5C17.7 16.2 17.6 15.6 17.8 15.1C18.1 14.6 18.7 14.5 19.2 14.7L19.9 15.1C20.4 15.4 20.5 16 20.3 16.5C20.1 16.8 19.8 17 19.4 17ZM20.4 13H19.9C19.3 13 18.9 12.6 18.9 12C18.9 11.4 19.3 11 19.9 11H20.4C21 11 21.4 11.4 21.4 12C21.4 12.6 20.9 13 20.4 13ZM18.9 9.30005C18.6 9.30005 18.2 9.10005 18 8.80005C17.7 8.30005 17.9 7.70002 18.4 7.40002L18.6 7.30005C19.1 7.00005 19.7 7.19995 20 7.69995C20.3 8.19995 20.1 8.79998 19.6 9.09998L19.4 9.19995C19.3 9.19995 19.1 9.30005 18.9 9.30005Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Activations</span>
                    <span className="menu-arrow"></span>
                </span>
                <div className="menu-sub menu-sub-accordion">
                    <div className="menu-item">
                        <NavLink className="menu-link" to="/activation-management/activations">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Activation list</span>
                        </NavLink>
                    </div>
                    <div className="menu-item">
                        <NavLink className="menu-link" to="/activation-management/create-activation">
                            <span className="menu-bullet">
                                <span className="bullet bullet-dot"></span>
                            </span>
                            <span className="menu-title">Create Activation</span>
                        </NavLink>
                    </div>
                </div>
            </div>
            {permissions["view_project"] &&
            <div className="menu-item">
                <NavLink className="menu-link" to="/locations">
                    <span className="menu-icon">
                    <span class="svg-icon svg-icon-muted svg-icon-2"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path opacity="0.3" d="M18.0624 15.3453L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3453C4.56242 13.6453 3.76242 11.4453 4.06242 8.94534C4.56242 5.34534 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24534 19.9624 9.94534C20.0624 12.0453 19.2624 13.9453 18.0624 15.3453Z" fill="black"/>
                    <path d="M12.0624 13.0453C13.7193 13.0453 15.0624 11.7022 15.0624 10.0453C15.0624 8.38849 13.7193 7.04535 12.0624 7.04535C10.4056 7.04535 9.06241 8.38849 9.06241 10.0453C9.06241 11.7022 10.4056 13.0453 12.0624 13.0453Z" fill="black"/>
                    </svg></span>
                    </span>
                    <span className="menu-title">Locations</span>
                </NavLink>
            </div>}
        </div>
        {/*end::Menu*/}
    </div>
  )
}
