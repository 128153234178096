import React, {useState, useEffect} from 'react'
import ToolsAndAccessoriesMultipleSearchComponent from '../../Utilities/ToolsAndAccessoriesMultipleSearchComponent'
import { useSelector } from 'react-redux'
import api from '../../../store/api'
import { useParams } from 'react-router'

export default function ConfigureToolds({handleUpdateTools}) {

    const [toolsData, setToolsData] = useState([])
    
    const {id} = useParams()

    const token = useSelector((state) => state.loginSlice.token);

    const [successfullSubmission, setSuccessfullSubmission] = useState(false)

    const [removedTools, setRemovedTools] = useState([])

    const [loading, setLoading] = useState(false)

    const getExistingTools = () => {
        api.get(`/api/activations/configure-tools-and-accessories/${id}/tools/`,
            {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
            .then((response) => {
                if (response.status === 200) {
                    const data = response.data
                    if (data.results[0]){
                        setToolsData(data.results[0].activation_tools)
                    }
                }
            })
            .catch((error) => {
                throw new Error('Error getting data.')
            })
    }

    useEffect(() => {
        getExistingTools()
    }, [])

    const handleSubmit = (evt) => {
        evt.preventDefault()
        // setLoading(true)
        if (toolsData.length > 0) {
            const data = {tools: toolsData, removedTools: removedTools}
            api.post(`/api/activations/configure-tools-and-accessories/${id}/tools/`, data,
              {headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Token ${token}`,
                  },
              })
              .then((response) => {
                  if (response.status === 201) {
                        const data = response.data.tools_data
                        setLoading(false)
                        if (data !== null || data !== undefined)
                            handleUpdateTools(data, response.data.message)
                      } 
                })
              .catch((error) => {
                setLoading(false)
            })
        }
    }

    const removeTool = (id) => {
        const updatedToolsData = toolsData.filter(tool => tool.tool_id !== id);
        setToolsData(updatedToolsData);
        setRemovedTools([...removedTools, id]) // activation_tool
    }

    return (
        <div className="w-100">
        <div className="d-flex flex-column mt-7 fv-row col-md-12">
            <div>
                <ToolsAndAccessoriesMultipleSearchComponent returnResults = {(result) => {
                    if (result) {
                        const id = result.id
                        const exists = toolsData.some(tool => tool.tool_id === id);
                        if (!exists) {
                            setToolsData([...toolsData, {'tool_name': result.tool_name, 'id': id, 'quantity': 1, 'tool_id': result.id}])
                        }

                        const removedIndex = removedTools.indexOf(result.id)
                        if (removedIndex !== -1) {
                            setRemovedTools(prevRemovedTools => prevRemovedTools.filter(toolId => toolId !== result.id));
                        }
                    }
                }} left={"75%"} clause={'tools'} successfullSubmission={successfullSubmission} inputName={'Add Activation Tools'} selectedTools={toolsData.map(tool => tool.tool_id)} /> 
                <div className='row'>
                    {toolsData.length > 0 &&
                    <div className='col-md-12'>
                        <div style={{maxHeight: '400px', overflow: 'auto', marginTop: '15px', border: '1px solid #fcc282', padding: '10px', borderRadius: '10px'}}>
                            <p>Tools</p>
                            <ul>
                            {toolsData.map((tool, index) => (
                                <li 
                                    key={index} 
                                    style={{
                                        display: 'flex', 
                                        justifyContent: 'space-between', 
                                        alignItems: 'center', 
                                        fontSize: '15px', 
                                        marginRight: '15px', 
                                        marginTop: '10px'
                                    }} 
                                    className='badge badge-light-primary'
                                >
                                    <span>{tool.tool_name}</span>
                                    <div 
                                        style={{
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            alignItems: 'center', 
                                            fontSize: '15px', 
                                            marginRight: '15px', 
                                            marginTop: '10px'
                                        }}
                                    >
                                        <label htmlFor={`quantity-${index}`}>Quantity:</label>
                                        <input 
                                            type="number" 
                                            id={`quantity-${index}`} 
                                            name="quantity" 
                                            onChange={(e) => {
                                                const newQuantity = parseInt(e.target.value, 10);
                                                setToolsData(prevToolsData =>
                                                    prevToolsData.map((t, i) =>
                                                        i === index ? { ...t, quantity: newQuantity } : t
                                                    )
                                                );
                                            }} 
                                            className="narrow-input" 
                                            min="1" 
                                            value={tool.quantity ? tool.quantity : 1}
                                        />
                                        <span 
                                            onClick={() => removeTool(tool.tool_id)} 
                                            className='bi bi-x fs-3' 
                                            style={{ cursor: 'pointer' }}
                                        ></span>
                                    </div>
                                </li>
                            ))}

                            </ul>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'right'}} className='mt-5'>    
            <button onClick={handleSubmit} disabled={loading ? true : false} id="kt_new_password_submit" className="btn btn-sm btn-beysix fwt-bolder">
                {!loading && <span className="indicator-label">Submit</span>}
                {loading && <span className="indicator-progress">Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
            </button>
        </div>
    </div>
    )
}
