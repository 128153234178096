import React, {useState, useEffect} from 'react'
import api from '../../../store/api'
import { useSelector } from 'react-redux'
import LocationsTable from '../LocationsTable/LocationsTable'


export default function Locations() {
    const [pageLoading, setPageLoading] = useState(true)
    const [resultsFound, setResultsFound] = useState(null)
    const [locationsData, setLocationsData] = useState([])
    const {organization, token, user} = useSelector((state) => {
        return {
          'organization': state.loginSlice.organization, 
          'token': state.loginSlice.token,
          'user': state.loginSlice.user
        }
    }); 

    const getLocationsData = () => {
        let url = `/api/activations/get-locations/`
        api.get(url, {headers: {'Content-Type': 'application/json', Authorization: `Token ${token}`}})
          .then((response) => {
            if (response.status === 200) {
                const data = response.data
                if (Object.keys(data).length > 0){
                    setResultsFound(true)
                    setLocationsData(data)
                } else {
                    setResultsFound(false)
                }
                setPageLoading(false)
            }
          })
            .catch((error) => {
                throw new Error(error) 
            })
      }


      useEffect(() => {
        getLocationsData()
      }, [])


  return (
    <LocationsTable locationData={locationsData} pageLoading={pageLoading} resultsFound={resultsFound} />
  )
}
