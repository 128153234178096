import React, {useState, useEffect, useCallback} from 'react'
import { useDropzone } from 'react-dropzone';
import MaterialBulkSearchComponent from '../../Utilities/MaterialBulkSearchComponent';
import MainModal from '../../Modals/MainModal';
import CreateMaterial from '../../Materials/CreateMaterial/CreateMaterial';
import api from '../../../store/api';
import { useSelector } from 'react-redux';
import FeedbackModal from '../../Utilities/FeedbackModal';

// made modal defines whether the item is being created from a modal
export default function CreateItem({madeModal = null, returnNewItem = null}) {

    const [itemData, setItemData] = useState({
        itemName: '',
        itemLength: '',
        itemWidth: '',
        itemHeight: '',
        itemDescription: '',
        itemQuantity: 1,
        itemAgingUsage: '',
        itemCost: 0,
        itemImages: [],
		itemMaterials: []
    })

	const [res, setRes] = useState({
		message: '',
		severity: "success",
		show: false
	})
	
	const [errors, setErrors] = useState({
        itemNameError: '',
        itemLengthError: '',
        itemWidthError: '',
        itemHeightError: '',
        itemDescriptionError: '',
        itemQuantityError: '',
        itemAgingUsageError: '',
        itemCostError: '',
        itemImagesError: '',
		itemMaterialsError: ''
    })
	const [selectedMaterials, setSelectedMaterials] = useState([])
    const [currentTab, setCurrentTab] = useState(1)
    const maxTab = 4
	const [submitLoading, setSubmitLoading] = useState(false)
	const [successfullSubmission, setsuccessfullSubmission] = useState(false)
	const token = useSelector((state) => state.loginSlice.token);
    const handleNext = () => {
        if (currentTab === 1 && handleValidateStep1()) {
			setCurrentTab(currentTab + 1)
		} 
		
		else if (currentTab !== 1) {
			setCurrentTab(currentTab + 1)
		}
    }

    const handlePrevious = () => {
        setCurrentTab(currentTab - 1)
    }

	const [itemDescription, setItemDescriptions] = useState({})

	const handleDescriptionChange = (index, event) => {
		const value = event.target.value
		const _itemDescription = itemDescription
		_itemDescription[index] = {[index + 1]: value}
		setItemDescriptions(_itemDescription)
	}

    const handleSubmit = (evt) => {
        evt.preventDefault()
        const formData = new FormData();
        
        formData.append('itemName', itemData.itemName);
        formData.append('itemLength', itemData.itemLength);
        formData.append('itemWidth', itemData.itemWidth);
        formData.append('itemHeight', itemData.itemHeight);
        formData.append('itemDescription', itemData.itemDescription);
        formData.append('itemQuantity', itemData.itemQuantity);
        formData.append('itemAgingUsage', itemData.itemAgingUsage);
        formData.append('itemCost', itemData.itemCost);
		formData.append('itemDescriptions', JSON.stringify(itemDescription))

        itemData.itemImages.forEach((img, index) => {
            formData.append('itemImages', img);
        });

        itemData.itemMaterials.forEach((material, index) => {
            formData.append('itemMaterials', material);
        });

		if (formData) {
			setSubmitLoading(true)
			api.post(`/api/items/`, formData,
                {headers: {
						'Content-Type': 'multipart/form-data',
                        Authorization: `Token ${token}`,
                    },
                })
                .then((response) => {
                    if (response.status === 201) {
						setRes({
							message: response.data.message,
							severity: "success",
							show: true
						})
						setItemData({
							itemName: '',
							itemLength: '',
							itemWidth: '',
							itemHeight: '',
							itemDescription: '',
							itemQuantity: 1,
							itemAgingUsage: '',
							itemCost: 0,
							itemImages: [],
							itemMaterials: []
						})
						setErrors({
							itemNameError: '',
							itemLengthError: '',
							itemWidthError: '',
							itemHeightError: '',
							itemDescriptionError: '',
							itemQuantityError: '',
							itemAgingUsageError: '',
							itemCostError: '',
							itemImagesError: '',
							itemMaterialsError: ''
						})
						setSelectedMaterials([])
						setCurrentTab(1)
						setItemImages([])
						setSubmitLoading(false)
						if (madeModal === true) {
							returnNewItem(response.data.data)
						}
                    }
                })
                .catch((error) => {
                    setRes({
						message: error.response.data.error,
						severity: "error",
						show: true
					})
					setSubmitLoading(false)
                })
		}
    }

	const handleChange = (evt) => {
		const name = evt.target.name;
		let value = evt.target.value;
		if (name === 'itemQuantity') {
			value = isNaN(parseInt(value)) || parseInt(value) < 1 ? 1 : parseInt(value);
		}
		setItemData({...itemData, [name]: value});
	}

  	const [itemImages, setItemImages] = useState([]);

	  const onDrop = useCallback((acceptedFiles) => {
		
		const newImages = acceptedFiles
			.filter(file => !itemImages.some(img => img.name === file.name))
			.map(file => Object.assign(file, {
				preview: URL.createObjectURL(file)
			}));
			setItemImages(prevImages => {
				const updatedImages = [...prevImages, ...newImages];
				return updatedImages;
			});
	
			setItemData(prevData => {
				const updatedItemData = {
					...prevData,
					itemImages: [...prevData.itemImages, ...acceptedFiles]
				};
				return updatedItemData;
			});
	}, [itemImages, setItemImages, setItemData]);
	
	

	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop
	});

	const handleRemoveImage = (index) => {
		setItemImages((prevImages) => prevImages.filter((_, i) => i !== index));
	};

	const handleRemoveMaterial = (indexToRemove, materialId) => {
		setSelectedMaterials(prevMaterials => prevMaterials.filter((_, index) => index !== indexToRemove));
		const _materialIds = itemData.itemMaterials
		for (let i = 0; i < _materialIds.length; i++) {
			if (_materialIds[i] === materialId) {
				_materialIds.splice(i, 1)
			}
		}
		setItemData({...itemData, _materialIds})
	};

	const [materialModal, setMaterialModal] = useState(false)

	const handleCloseModal = () => {
		setMaterialModal(false)
	}

	const handleAddMaterials = (materialsArr) => {
		const latestAddedMaterial = materialsArr[materialsArr.length - 1];
		setSelectedMaterials([...selectedMaterials, latestAddedMaterial])
		const _materialIds = itemData.itemMaterials
		_materialIds.push(latestAddedMaterial.id)
	}

	const handleValidateStep1 = () => {
		if (itemData.itemName.length === 0) {
			setErrors({...errors, itemNameError: 'You must enter a name for your item.'})
			return false
		} else {
			setErrors({...errors, itemNameError: ''})
		}

		if (itemData.itemQuantity === '') {
			setErrors({...errors, itemQuantityError: 'You must enter a quantity for your item.'})
			return false
		} else {
			setErrors({...errors, itemQuantityError: ''})
		}
		return true
	}
	  

  return (
    <div>
		<MainModal 
            handleClose={() => setMaterialModal(false)} 
            show={materialModal}
            modalTitle='Create a new material'>
            <CreateMaterial handleCloseModal={handleCloseModal} setMaterials={handleAddMaterials} materials={selectedMaterials}/>
        </MainModal>
		<FeedbackModal message={res.message} severity = {res.severity} show = {res.show} onClose = {() => {
			setRes({
				message: "",
				severity: "success",
				show: false
			})
		}}/>
		<div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid first" id="kt_create_account_stepper" data-kt-stepper="true">
									{/*begin::Aside*/}
									{!madeModal === true &&<div className="d-flex justify-content-center bg-body rounded justify-content-xl-start flex-row-auto w-xl-300px w-xxl-400px me-9">
										{/*begin::Wrapper*/}
										<div className={"px-6 px-lg-10 px-xxl-15 py-20"}>
											{/*begin::Nav*/}
											 
											<div className="stepper-nav">
												{/*begin::Step 1*/}
												<div className={currentTab === 1 ? "stepper-item current" : "stepper-item pending"} data-kt-stepper-element="nav">
													{/*begin::Line*/}
													<div className="stepper-line w-40px"></div>
													{/*end::Line*/}
													{/*begin::Icon*/}
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">1</span>
													</div>
													{/*end::Icon*/}
													{/*begin::Label*/}
													<div className="stepper-label">
														<h3 className="stepper-title">Item Basic Details</h3>
														<div className="stepper-desc fw-bold">Setup Your Item Details</div>
													</div>
													{/*end::Label*/}
												</div>
												<div className={currentTab === 2 ? "stepper-item current" : "stepper-item pending"} data-kt-stepper-element="nav">
													{/*begin::Line*/}
													<div className="stepper-line w-40px"></div>
													{/*end::Line*/}
													{/*begin::Icon*/}
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">2</span>
													</div>
													<div className="stepper-label">
														<h3 className="stepper-title">Item Basic Details</h3>
														<div className="stepper-desc fw-bold">Setup Your Item Details</div>
													</div>
												</div>
												{/*end::Step 1*/}
												{/*begin::Step 2*/}
												<div className={currentTab === 3 ? "stepper-item current" : "stepper-item pending"} data-kt-stepper-element="nav">
													{/*begin::Line*/}
													<div className="stepper-line w-40px"></div>
													{/*end::Line*/}
													{/*begin::Icon*/}
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">3</span>
													</div>
													{/*end::Icon*/}
													{/*begin::Label*/}
													<div className="stepper-label">
														<h3 className="stepper-title">Configure Item Individually</h3>
														<div className="stepper-desc fw-bold">Provide unique descriptions for the items based on the Quantity.</div>
													</div>
													{/*end::Label*/}
												</div>
												{/*end::Step 2*/}
												{/*begin::Step 3*/}
												<div className={currentTab === 4 ? "stepper-item current" : "stepper-item pending"} data-kt-stepper-element="nav">
													{/*begin::Line*/}
													<div className="stepper-line w-40px"></div>
													{/*end::Line*/}
													{/*begin::Icon*/}
													<div className="stepper-icon w-40px h-40px">
														<i className="stepper-check fas fa-check"></i>
														<span className="stepper-number">4</span>
													</div>
													{/*end::Icon*/}
													{/*begin::Label*/}
													<div className="stepper-label">
														<h3 className="stepper-title">Configure Materials</h3>
														<div className="stepper-desc fw-bold">Configure Materials For Your Item</div>
													</div>
													{/*end::Label*/}
												</div>
												{/*end::Step 3*/}
											</div>
											{/*end::Nav*/}
										</div>
										{/*end::Wrapper*/}
									</div>}
									{/*begin::Aside*/}
									{/*begin::Content*/}
									<div className="bg-body rounded" style={{width: '100%'}}>
										{/*begin::Form*/}
										<form className={!madeModal === true && "py-20 w-100 w-xl-700px px-9 fv-plugins-bootstrap5 fv-plugins-framework"} novalidate="novalidate" id="kt_create_account_form">
											{/*begin::Step 1*/}
											<div className={currentTab === 1 ? "current" : "pending"} data-kt-stepper-element="content">
												{/*begin::Wrapper*/}
												<div className="w-100">
													<div className="pb-10 pb-lg-15">
													<h2 className="ftw-bolder d-flex align-items-center text-dark">Item Basic Details
													<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
													<div className="text-muted fw-bold fs-6">Setup Your Item Details</div>
													</div>
													<div className='row'>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Item Name</label>
                                                            <input type="text" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemName} name="itemName" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">{errors.itemNameError}</div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Item Cost</label>
                                                            <input type="number" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemCost} name="itemCost" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Item Length</label>
                                                            <input type="number" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemLength} name="itemLength" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Item Width</label>
                                                            <input type="number" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemWidth} name="itemWidth" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Item Height</label>
                                                            <input type="number" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemHeight} name="itemHeight" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Aging Usage (Days)</label>
                                                            <input type="number" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemAgingUsage} name="itemAgingUsage" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                        <div className="mb-10 fv-row fv-plugins-icon-container">
														<label className="form-label mb-3">Quantity</label>
                                                            <input type="number" className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemQuantity} name="itemQuantity" placeholder="" />
                                                            <div className="fv-plugins-message-container invalid-feedback">{errors.itemQuantityError}
                                                            </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-12'>
															<div className="mb-10 fv-row fv-plugins-icon-container">
															<label className="form-label mb-3">Item Description</label>
															<textarea className="form-control form-control-lg form-control-solid" onChange={handleChange} value={itemData.itemDescription} name="itemDescription" rows="10" cols="30" placeholder="Enter your item description here..." />
															<div className="fv-plugins-message-container invalid-feedback"></div></div>
                                                        </div>
                                                    </div>
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Step 1*/}
											{/*begin::Step 2*/}
											<div data-kt-stepper-element="content" className={currentTab === 2 ? "current" : "pending"} style={{flexDirection: 'column'}}>
											{/*begin::Input group*/}
											<div className="pb-10 pb-lg-15">
												<h2 className="ftw-bolder d-flex align-items-center text-dark">Upload Item Images
												<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
												<div className="text-muted fw-bold fs-6">Upload Images For Your Item</div>
											</div>
											<div className="fv-row">
												{/*begin::Dropzone*/}
												<div {...getRootProps({ className: 'dropzone' })} >
													{/*begin::Message*/}
													<div className="dz-message needsclick">
														{/*begin::Icon*/}
														<i className="bi bi-file-earmark-arrow-up text-primary-beysix fs-3x"></i>
														{/*end::Icon*/}
														<input {...getInputProps()} />
														{/*begin::Info*/}
														<div className="ms-4">
															<h3 className="fs-5 ftw-bold text-gray-900 mb-1">Drop files here or click to upload.</h3>
															<span className="fs-7 ftw-bold text-gray-400">Upload up to 10 images</span>
														</div>
														{/*end::Info*/}
													</div>
												</div>
												{/*end::Dropzone*/}
											</div>
											{itemImages.length > 0 && <div className="ms-4" style={{margin: "20px 10px"}}><h3 className="fs-5 ftw-bold text-gray-900 mb-1">{`You have selected ${itemImages.length} images`}</h3></div>}
											<div className='upload-images-wrapper row'>
												{itemImages.map((file, index) => (
												<div key={index} className='single-image-wrapper col-md-3'>
													<div className='uploaded-image-overlay'></div>
													<div className='delete-uploaded-image' onClick={() => handleRemoveImage(index)}>
													<span className='bi bi-x fs-3'></span>
													</div>
													<img className = 'uploaded-image' src={file.preview} alt={file.name} />
												</div>
												))}
											</div>
											</div>
											{/*begin::Step 2*/}
											<div data-kt-stepper-element="content" className={currentTab === 3 ? "current" : "pending"} style={{flexDirection: 'column'}}>
											{/*begin::Input group*/}
											<div className="pb-10 pb-lg-15">
												<h2 className="ftw-bolder d-flex align-items-center text-dark">Configure Item Individually
												<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
												<div className="text-muted fw-bold fs-6">Provide unique descriptions for the items based on the Quantity.</div>
											</div>
											<div className="fv-row">
											{Array.from({ length: itemData.itemQuantity }).map((_, index) => (
													<div key={index} className="mb-10 fv-row fv-plugins-icon-container">
													<label className="form-label mb-3">{itemData.itemName} #{index + 1}</label>
													<textarea
														className="form-control form-control-lg form-control-solid"
														onChange={(event) => handleDescriptionChange(index, event)}
														value={itemDescription[index] && itemDescription[index][index + 1]}
														name="itemDescription"
														rows="4"
														cols="30"
														placeholder={`${itemData.itemName} #${index + 1}`}
													/>
													<div className="fv-plugins-message-container invalid-feedback"></div>
													</div>
												))}
											</div>
											</div>
											{/*end::Step 2*/}
											{/*begin::Step 3*/}
											<div data-kt-stepper-element="content" className={currentTab === 4 ? "current" : "pending"} style={{flexDirection: 'column'}}>
												<div className="pb-10 pb-lg-15">
													<h2 className="ftw-bolder d-flex align-items-center text-dark">Configure Materials
													<i className="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" title="" data-bs-original-title="Billing is issued based on your selected account type" aria-label="Billing is issued based on your selected account type"></i></h2>
													<div className="text-muted fw-bold fs-6">Configure Materials For Your Item</div>
												</div>
												<div className='row'>
													<div className = 'col-md-11'>
														<MaterialBulkSearchComponent returnResults = {(material) => {
														if (material) {
															if (!itemData.itemMaterials.includes(material.id)) {
																const _materials = itemData.itemMaterials
																_materials.push(material.id)
																setItemData({...itemData, itemMaterials: _materials})
																setSelectedMaterials([...selectedMaterials, material])
															}
														}
														}} successfullSubmission={successfullSubmission} />
													</div>
													<div className = 'col-md-1'>
														<div className='add-item-btn-wrapper'>
															<span className='add-item-btn' onClick={() => setMaterialModal(true)}><i className="bi bi-plus"></i></span>
														</div>
													</div>
												</div>
												{selectedMaterials.length > 0 &&
												<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
												{selectedMaterials.map((material, index) => { 
													return (
														<div key={index} className="badge badge-light-primary" style={{ display: 'inline-flex', alignItems: 'center', fontSize: '15px', marginRight: '15px', marginTop: '10px' }}>
															<span onClick={() => handleRemoveMaterial(index, material.id)} className="bi bi-x fs-3" style={{ marginRight: '15px', cursor: 'pointer' }}></span>
															<span>{material.Material_name}</span>
														</div>
													)})}
												</div>}
											</div>
											{/*end::Step 3*/}
											{/*begin::Actions*/}
											<div className="d-flex flex-stack pt-10">
												{/*begin::Wrapper*/}
												<div className="mr-2">
													{currentTab !== 1 && <button type="button" onClick={handlePrevious} className="btn btn-lg btn-light-primary me-3">
													{/*begin::Svg Icon | path: icons/duotune/arrows/arr063.svg*/}
													<span className="svg-icon svg-icon-4 me-1">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="black"></rect>
															<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="black"></path>
														</svg>
													</span>
													{/*end::Svg Icon*/}Back</button>}
												</div>
												{/*end::Wrapper*/}
												{/*begin::Wrapper*/}
												<div>
													{currentTab === maxTab ? <button type="button" className="btn btn-lg btn-beysix me-3" onClick={handleSubmit} disabled={submitLoading ? true : false} >
														{!submitLoading ? <span className="indicator-label">Submit
														{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
														<span className="svg-icon svg-icon-3 ms-2 me-0 svg-icon-white">
															<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
																<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
																<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
															</svg>
														</span>
														{/*end::Svg Icon*/}</span> :
														<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>}
													</button> :
													<button type="button" className="btn btn-lg btn-beysix" onClick={handleNext}>Continue
													{/*begin::Svg Icon | path: icons/duotune/arrows/arr064.svg*/}
													<span className="svg-icon svg-icon-4 ms-1 me-0">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)" fill="black"></rect>
															<path d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z" fill="black"></path>
														</svg>
													</span>
													{/*end::Svg Icon*/}</button>}
												</div>
												{/*end::Wrapper*/}
											</div>
											{/*end::Actions*/}
										<div></div><div></div><div></div><div></div></form>
										{/*end::Form*/}
									</div>
									{/*end::Content*/}
								</div>
								{/*end::Stepper*/}
							</div>
  )
}
