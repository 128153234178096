import React, { useState, useEffect, useRef } from 'react';

export default function ActionsDdl({actions}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);  // Create a reference for the dropdown

  // Toggle dropdown visibility when button is clicked
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);  // Close the dropdown
      }
    };

    // Add event listener to detect clicks outside the dropdown
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Cleanup event listener when component is unmounted
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <>
      {/* Button to toggle dropdown */}
      <button
        className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
        onClick={toggleDropdown}
      >
        <i className="bi bi-three-dots fs-3"></i>
      </button>

      {/* Dropdown Menu */}
      <div
        ref={dropdownRef}
        className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary ftw-bold py-3 ${isDropdownOpen ? 'show' : ''}`}
        style={{maxWidth: '208px', zIndex: 105, position: 'fixed', inset: '0px 0px auto auto', margin: '0px', transform: 'translate3d(0px, 160px, 0px)', right: '2%' }}
        data-popper-placement="bottom-end"
      >
        {/* Begin Heading */}
        <div className="menu-item px-3">
          <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Actions</div>
        </div>
        {/* End Heading */}
        {actions.map((action) => 
        <div className="menu-item px-3 ftw-bold fs-7">
          <a onClick={action.returnFunction} className="menu-link px-3" style={{textTransform: 'capitalize'}}>{action.name}</a>
        </div>
        )}
      </div>
    </>
  );
}
